// extracted by mini-css-extract-plugin
export var clickArea = "index-module--clickArea--bFOvL";
export var content = "index-module--content--M6PU0";
export var contentHover = "index-module--contentHover--qAGx6";
export var descBg = "index-module--descBg--dlS--";
export var descContent = "index-module--descContent--a9xqA";
export var experienceDemo = "index-module--experienceDemo--vD17c";
export var experienceKPI = "index-module--experienceKPI--ZC86B";
export var guidance = "index-module--guidance--NT-I3";
export var guidanceLogo = "index-module--guidanceLogo--J-c8R";
export var hoverShow = "index-module--hoverShow--hJbOg";
export var hoverUp = "index-module--hover-up--bHxmq";
export var media = "index-module--media--8HLxV";
export var position_left = "index-module--position_left--ieiZ+";
export var viewData = "index-module--viewData--GXSxw";
export var watchVideo = "index-module--watchVideo--aua+D";